'use strict';

(function () {
    const appHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight();


    document.addEventListener('DOMContentLoaded', () => {
        const endDate = new Date('August 21, 2024 00:00:00');
        const endTime = endDate.getTime() / 1000;
        new FlipDown(endTime, {
            theme: 'dark',
        })

            // Start the countdown
            .start()

            // Do something when the countdown ends
            .ifEnded(() => {
                console.log('The countdown has ended!');
            });
    });
})();
